ion-range {
  &.best-price {
    --knob-size: 20px;
    --knob-background: white;
    --knob-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    --bar-background-active: transparent;
    --bar-background: linear-gradient(270deg, #2ddf00 0%, #ffe600 50%, #ff0000 100%), #c4c4c4;
    --bar-height: 10px;
    --bar-border-radius: 4px;
  }
}

ion-input,
ion-select,
ion-datetime,
ion-textarea,
ion-checkbox {
  --checkmark-color: var(--way-style-blockbg);
  background: var(--way-style-blockbg) !important;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(34, 26, 56, 0.05);
  --padding-start: 16px !important;
}

.custom-form-input {
  width: 100%;
  height: 44px;
  padding: 0.66rem;
  border: transparent;
  border-radius: 0.4rem;
  background-color: var(--way-style-blockbg) !important;
}
