:root {
  --base-content-padding: 0.7em;
  --mission-block-padding-top: 0.3em;
  --mission-block-text-margin-left: 1.6em;
}

.is-flex {
  display: flex;
}

.bg-white {
  background: white;
}

/* work-sans-100 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 100;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-200 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 200;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-300 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-regular - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-500 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-600 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-700 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-800 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-900 - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-200italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 200;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-100italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 100;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-300italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-500italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 500;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-700italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-600italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-800italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 800;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-900italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 900;
  src: url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.woff2") format("woff2"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.woff") format("woff"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.ttf") format("truetype"), url("../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
:root {
  --ion-font-family: "Work Sans";
  font-family: "Work Sans";
  font-size: 13px;
}
:root ion-text.font-bold {
  font-weight: bold;
}
:root h2 {
  margin-top: 10px;
  font-size: 20px;
}
:root h3 {
  margin-top: 6px;
  font-size: 16px;
}

/* josefin-sans-100 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 100;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-200 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-300 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-400 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-500 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-600 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-700 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-800 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-900 */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-100italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 100;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-200italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 200;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-300italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 300;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-400italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-500italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 500;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-600italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 600;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-700italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-800italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 800;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/* josefin-sans-900italic */
@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 900;
  src: local(""), url("../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf") format("truetype");
}
/** Ionic CSS Variables **/
:root {
  --ion-background-color: var(--way-style-blockbg);
  --ion-background-color-rgb: 255,255,255;
  --secondary-background-color: var(--way-style-pagebg);
  --ion-text-color: var(--way-color-d2);
  --ion-text-color-rgb: 47, 56, 69;
  --ion-border-color: transparent;
  --ion-toolbar-background: var(--way-style-blockbg);
  --ion-item-background: transparent;
  /** primary **/
  --ion-color-primary: var(--way-style-brand);
  --ion-color-primary-rgb: 42, 90, 164;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #103f88;
  --ion-color-primary-tint: #2a5aa4;
  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ff9900;
  --ion-color-warning-rgb: 255, 153, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e08700;
  --ion-color-warning-tint: #ffa31a;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  /** waynium danger **/
  --ion-color-waynium-danger: #bb0000;
  --ion-color-waynium-danger-rgb: 250,47,2;
  --ion-color-waynium-danger-contrast: #ffffff;
  --ion-color-waynium-danger-contrast-rgb: 255,255,255;
  --ion-color-waynium-danger-shade: #dc2902;
  --ion-color-waynium-danger-tint: #fb441b;
  /** waynium warning **/
  --ion-color-waynium-warning: #ff9900;
  --ion-color-waynium-warning-rgb: 255,153,0;
  --ion-color-waynium-warning-contrast: #000000;
  --ion-color-waynium-warning-contrast-rgb: 0,0,0;
  --ion-color-waynium-warning-shade: #e08700;
  --ion-color-waynium-warning-tint: #ffa31a;
  /** waynium ok **/
  --ion-color-waynium-ok: #54a900;
  --ion-color-waynium-ok-rgb: 84,169,0;
  --ion-color-waynium-ok-contrast: #ffffff;
  --ion-color-waynium-ok-contrast-rgb: 255,255,255;
  --ion-color-waynium-ok-shade: #4a9500;
  --ion-color-waynium-ok-tint: #65b21a;
  /** waynium mid #1 **/
  --ion-color-waynium-light1: #D0D4E5;
  /** WAYNIUM PALETTE **/
  /** waynium brand */
  --way-color-brand: #12489A;
  /** waynium light #1 */
  --way-color-l1: #FFFFFF;
  /** waynium light #2 */
  --way-color-l2: #F6F8FF;
  /** waynium mid #1 */
  --way-color-m1: #E8ECFA;
  /** waynium mid #2 */
  --way-color-m2: #D0D4E5;
  /** waynium dark #1 */
  --way-color-d1: #6C769A;
  /** waynium dark #2 */
  --way-color-d2: #2F3845;
  /********** COLOR STYLES **********/
  /* Page background **/
  --way-style-pagebg: var(--way-color-l2);
  /* Block background **/
  --way-style-blockbg: var(--way-color-l1);
  /** Brand **/
  --way-style-brand: var(--way-color-brand);
  --way-style-brand-contrast: var(--way-color-l1);
  --way-color-brand-immutable: #12489A;
  --way-color-brand-alt: var(--way-color-brand);
  /* Primary text **/
  --way-style-txt-primary: var(--way-color-d2);
  /* Secondary text **/
  --way-style-txt-secondary: var(--way-color-d1);
  /* Tertiary text **/
  --way-style-txt-tertiary: var(--way-color-d2);
  /* Borders & separators **/
  --way-style-block-border: var(--way-color-m1);
  --way-style-page-border: var(--way-color-m2);
}

/*
 * Dark Colors
 * -------------------------------------------
 */
@media (prefers-color-scheme: dark) {
  :root {
    --way-style-txt-primary: #FFFFFF;
    --ion-background-color: var(--way-style-blockbg);
    --ion-background-color-rgb: 0,0,0;
    --secondary-background-color: var(--way-style-pagebg);
    --ion-text-color: var(--way-style-txt-primary);
    --ion-text-color-rgb: 255, 255, 255;
    --ion-color-primary: var(--way-style-brand);
    --ion-color-primary-rgb: 42, 90, 164;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #103f88;
    --ion-color-primary-tint: #2a5aa4;
    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;
    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;
    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;
    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    /** waynium danger **/
    --ion-color-waynium-danger: #f10707;
    --ion-color-waynium-danger-rgb: 250,47,2;
    --ion-color-waynium-danger-contrast: #ffffff;
    --ion-color-waynium-danger-contrast-rgb: 255,255,255;
    --ion-color-waynium-danger-shade: #dc2902;
    --ion-color-waynium-danger-tint: #fb441b;
    /** waynium ok **/
    --ion-color-waynium-ok: #c8ff91;
    --ion-color-waynium-ok-rgb: 84,169,0;
    --ion-color-waynium-ok-contrast: #ffffff;
    --ion-color-waynium-ok-contrast-rgb: 255,255,255;
    --ion-color-waynium-ok-shade: #4b752b;
    --ion-color-waynium-ok-tint: #bfda8f;
    /** WAYNIUM PALETTE **/
    /** waynium brand */
    --way-color-brand: #FFFFFF;
    /** waynium light #1 */
    --way-color-l1: #404557;
    /** waynium light #2 */
    --way-color-l2: #1C1D21;
    /** waynium mid #1 */
    --way-color-m1: #3B4051;
    /** waynium mid #2 */
    --way-color-m2: #555D76;
    /** waynium dark #1 */
    --way-color-d1: #CED2DD;
    /** waynium dark #2 */
    --way-color-d2: #FFFFFF;
    --way-style-txt-primary: var(--way-color-d2);
    --way-color-brand-alt: var( --way-color-l1);
  }
}
.ion-color-waynium-danger {
  --ion-color-base: var(--ion-color-waynium-danger);
  --ion-color-base-rgb: var(--ion-color-waynium-danger-rgb);
  --ion-color-contrast: var(--ion-color-waynium-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-waynium-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-waynium-danger-shade);
  --ion-color-tint: var(--ion-color-waynium-danger-tint);
}

.ion-color-waynium-warning {
  --ion-color-base: var(--ion-color-waynium-warning);
  --ion-color-base-rgb: var(--ion-color-waynium-warning-rgb);
  --ion-color-contrast: var(--ion-color-waynium-warning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-waynium-warning-contrast-rgb);
  --ion-color-shade: var(--ion-color-waynium-warning-shade);
  --ion-color-tint: var(--ion-color-waynium-warning-tint);
}

.ion-color-waynium-ok {
  --ion-color-base: var(--ion-color-waynium-ok);
  --ion-color-base-rgb: var(--ion-color-waynium-ok-rgb);
  --ion-color-contrast: var(--ion-color-waynium-ok-contrast);
  --ion-color-contrast-rgb: var(--ion-color-waynium-ok-contrast-rgb);
  --ion-color-shade: var(--ion-color-waynium-ok-shade);
  --ion-color-tint: var(--ion-color-waynium-ok-tint);
}

:root ion-grid {
  padding-inline-start: 0;
  padding-inline-end: 0;
}

ion-button, ion-back-button {
  text-transform: none;
  font-size: 1.23rem;
  font-weight: 500;
  --border-radius: 4px;
  --box-shadow: none;
}
ion-button.outline, ion-button[fill=outline], ion-back-button.outline, ion-back-button[fill=outline] {
  --border-width: 1px;
  --background: var(--way-color-l1);
  --color: var(--way-color-brand);
}

ion-button {
  --background: var(--way-color-brand);
  --color: var(--way-color-l1);
}

ion-back-button {
  --background: var(--way-color-l1);
  --color: var(--way-color-d2);
}

ion-back-button::ng-deep button {
  border: 1px red solid;
}

/** Button group **/
ion-footer ion-toolbar {
  padding: 0 0.5em 0 0.5em;
}

.btn-group {
  display: flex;
}

ion-button, ion-back-button {
  min-height: 48px;
  --padding-start: 0;
  --padding-end: 0;
  margin-left: 0;
  margin-right: 0;
  letter-spacing: 0;
  flex-basis: 100%;
  text-align: center;
}
ion-button:not(:last-child), ion-back-button:not(:last-child) {
  margin-right: 0.25em;
}
ion-button:not(:first-child), ion-back-button:not(:first-child) {
  margin-left: 0.25em;
}

form ion-item ion-input, form ion-item ion-textarea, form ion-item ion-select, form ion-item ion-datetime {
  --padding-start: 10px !important;
  --padding-end: 10px !important;
  --padding-top: 10px !important;
  --padding-bottom: 10px !important;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}
form ion-item ion-datetime {
  width: 100%;
}
form ion-item ion-select {
  width: 100%;
  max-width: 100%;
}
form ion-item ion-label {
  font-size: 1.5rem !important;
  font-weight: 500;
}
form ion-item ion-label[position=stacked] {
  margin-bottom: 10px !important;
}
form ion-item ion-label[position=fixed] {
  min-width: 200px !important;
}

hr.separator {
  padding: 0 0.8em;
  opacity: 0.3;
  width: 90%;
}
hr.separator.dashed {
  border-top: 1px dashed var(--way-style-block-border);
}

.distance,
.time {
  background-color: var(--way-color-l2);
}

:root remix-icon {
  display: block;
}
:root remix-icon.size-lg {
  width: 22px;
  height: 22px;
}
:root remix-icon.size-md {
  width: 20px;
  height: 20px;
}
:root remix-icon.size-sm {
  width: 17px;
  height: 17px;
}
:root remix-icon.size-xs {
  width: 13px;
  height: 13px;
}

ion-range.best-price {
  --knob-size: 20px;
  --knob-background: white;
  --knob-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  --bar-background-active: transparent;
  --bar-background: linear-gradient(270deg, #2ddf00 0%, #ffe600 50%, #ff0000 100%), #c4c4c4;
  --bar-height: 10px;
  --bar-border-radius: 4px;
}

ion-input,
ion-select,
ion-datetime,
ion-textarea,
ion-checkbox {
  --checkmark-color: var(--way-style-blockbg);
  background: var(--way-style-blockbg) !important;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(34, 26, 56, 0.05);
  --padding-start: 16px !important;
}

.custom-form-input {
  width: 100%;
  height: 44px;
  padding: 0.66rem;
  border: transparent;
  border-radius: 0.4rem;
  background-color: var(--way-style-blockbg) !important;
}

ion-content.secondary-background {
  --background: var(--secondary-background-color);
}

/**
 * Permet de copier/coller le texte de l'application avec un appuie long
 */
* {
  user-select: auto;
}

ion-card.content-card {
  --background: var(--way-style-blockbg);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.02), 0 2px 2px 0 rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.01);
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.02), 0 2px 2px 0 rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.01);
  border-radius: 3px;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}

:root .cal-hour {
  background-color: var(--way-color-l1);
}
:root .cal-hour.cal-hour-odd {
  background-color: var(--way-color-l2);
}