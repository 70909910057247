ion-button, ion-back-button {
  text-transform: none;
  font-size: 1.23rem;
  font-weight: 500;
  --border-radius: 4px;
  --box-shadow: none;

  &.outline, &[fill="outline"] {
    --border-width: 1px;
    --background: var(--way-color-l1);
    --color: var(--way-color-brand);
  }
}
ion-button {
  --background: var(--way-color-brand);
  --color: var(--way-color-l1);
}
ion-back-button {
  --background: var(--way-color-l1);
  --color: var(--way-color-d2);
}
ion-back-button::ng-deep {
  button {
    border: 1px red solid;
  }
}

/** Button group **/
ion-footer {
  ion-toolbar {
    padding: 0 0.5em 0 0.5em;
  }
}

.btn-group {
  display: flex;
}

ion-button, ion-back-button {
  min-height:48px;
  --padding-start: 0;
  --padding-end: 0;
  margin-left: 0;
  margin-right: 0;
  letter-spacing: 0;
  flex-basis: 100%;
  text-align: center;

  &:not(:last-child) {
    margin-right: 0.25em;
  }

  &:not(:first-child) {
    margin-left: 0.25em;
  }
}
