@import '../../../way-lib/src/theme/fonts/worksans.scss';

:root {
  --ion-font-family: 'Work Sans';
  font-family: 'Work Sans';
  font-size: 13px;

  ion-text {
    &.font-bold {
      font-weight: bold;
    }
  }

  h2 {
    margin-top: 10px;
    font-size: 20px;
  }

  h3 {
    margin-top: 6px;
    font-size: 16px;
  }

}



@import '../../../way-lib/src/theme/fonts/josefinsans.scss';
