ion-content {
  &.secondary-background {
    --background: var(--secondary-background-color);
  }
}

/**
 * Permet de copier/coller le texte de l'application avec un appuie long
 */
* {
  user-select: auto;
}