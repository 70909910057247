hr.separator {
  padding: 0 0.8em;
  opacity: 0.3;
  width: 90%;

  &.dashed {
    border-top: 1px dashed var(--way-style-block-border);

  }
}


.distance, 
.time {
  background-color: var(--way-color-l2);
}